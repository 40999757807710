// @ts-check

/** @typedef {import("@evolved/domain").SystemField} SystemField */

/** @type {SystemField} */
export const firstName = {
  entityType: "USER",
  type: "TEXT",
  title: "First Name",
  canEdit: true,
  isSystem: true,
  dataIndex: "firstName",
  isRequired: true,
  canMatchEntityBy: true,
};

/** @type {SystemField} */
export const lastName = {
  entityType: "USER",
  type: "TEXT",
  title: "Last Name",
  canEdit: true,
  isSystem: true,
  dataIndex: "lastName",
  isRequired: true,
  canMatchEntityBy: true,
};

/** @type {SystemField} */
export const email = {
  entityType: "USER",
  type: "TEXT",
  title: "Email",
  canEdit: true,
  isSystem: true,
  dataIndex: "email",
  isRequired: true,
  canMatchEntityBy: true,
};

export const COLLECTION = Object.freeze([firstName, lastName, email]);
