import { isMatchableField } from "./is-matchable-field";

/**
 * @param {Object} o
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {(string | null)[]} o.headerDataIndexes
 */
export const getDataIndexOptions = ({ headerDataIndexes, importFields }) => {
  return importFields
    .filter((field) => {
      return (
        isMatchableField(field) && headerDataIndexes.includes(field.dataIndex)
      );
    })
    .map(({ dataIndex, name }) => ({ label: name, value: dataIndex }));
};
