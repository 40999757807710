/**
 * @param {Object} o
 * @param {import("../domain").ImportField[]} o.importFields
 */
export const getRequiredFields = ({ importFields }) => {
  return importFields
    .filter(({ isRequired }) => {
      return !!isRequired;
    })
    .map(({ name, dataIndex }) => ({
      dataIndex,
      name,
    }));
};
