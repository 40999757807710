// @ts-check

import React from "react";
import { Alert, Button, Select } from "antd";
import {
  ArrowRightOutlined
} from "@ant-design/icons";

// TODO: this probably belongs in domain
export const CREATE_USER_DEFINED_FIELD_OPTION = Object.freeze({
  label: "Create User Defined Field",
  value: "create_user_defined_field",
});

/** @type {React.FC<{
 *  service: import("./import-state/import-state").ImportStateService;
 *  state: import("./domain").ImportState;
 *  onBack: () => void;
 *  onNext: (() => void) | null;
 * }>} */
export const IdentifyHeaders = (props) => {
  const {
    service,
    state,
    onBack,
    onNext,
  } = props;

  if (!state.upload) {
    throw new Error("file must be uploaded before this step.");
  }

  // go over each row and for each row, 

  /** @type {Record<string, number[]>} */
  const cardinalityErrors = {};

  for (const [index, row] of Object.entries(state.reducedRows)) {
    for (const info of row.info) {
      if (info.type === "relationship_match_info" && "cardinalityOverflow" in info) {
        cardinalityErrors[info.dataIndex] = [
          ...cardinalityErrors[info.dataIndex] ?? [],
          Number(index),
        ]
      }
    }
  }

  return (
    <div className="w-full">
      <div className={"flex w-full justify-between mb-4"}>
        <div className="w-[50%] font-bold">
          CSV Header</div>
        <div className="w-[50%] font-bold">
          CRM Field
        </div>
      </div>
      {state.upload.headers.map((header, index) => {
        const dataIndex = state.headers.config.dataIndexes[index];
        const options = state.headers.dataIndexOptions[index];

        return (
          <React.Fragment key={index}>
            <div className={"flex w-full justify-between mb-2"} key={header}>
              <div className="w-[50%]">{header}</div>
              <div className="w-[50%] flex">
                {options && (
                  <>
                    <ArrowRightOutlined className="mr-4" />
                    <Select
                      allowClear
                      onClear={() => {
                        service.setHeaderDataIndex({
                          value: null,
                          index,
                        });
                      }}
                      placeholder="skip"
                      className="grow"
                      value={dataIndex}
                      onChange={(value) => {
                        service.setHeaderDataIndex({
                          value,
                          index,
                        });
                      }}
                      options={options}
                    />
                  </>
                )}
                {!options && (
                  <strong>Exact Match</strong>
                )}
              </div>
            </div>
            {dataIndex && cardinalityErrors[dataIndex] ? (
              <Alert
                message={`
                  Field ${service.findField(dataIndex)} only supports one related entity. The following rows have more than one and will be ignored: ${cardinalityErrors[dataIndex].map(index => index + 1)}
                `}
                type="warning"
                className="!mb-3"
              />
            ) : null}
          </React.Fragment>
        );
      })}
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>
          Back
        </Button>
        <Button
          type="primary"
          disabled={!onNext}
          onClick={onNext ?? undefined}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
