// @ts-check

import { getDefaultDataIndexes } from "../configure-matches/get-default-data-indexes";

/**
 * @param {Object} o
 * @param {import("@evolved/domain").EntityType} o.entityType
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {{dataIndexes: string[][]}} o.matchConfig
 */
export const syncMatchConfigDataIndexes = (o) => {
  const { headerDataIndexes } = o;

  const next = o.matchConfig.dataIndexes
    .map((dataIndexes) => {
      return dataIndexes.filter((dataIndex) => {
        return headerDataIndexes.includes(dataIndex);
      });
    })
    .filter((dataIndexes) => {
      return dataIndexes.length;
    });

  if (next.length) {
    return next;
  }

  return getDefaultDataIndexes(o);
};
