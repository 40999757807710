// @ts-check

/** @typedef {import("@evolved/domain").SystemField} SystemField */

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

/** @type {SystemField} */
export const account = {
  entityType: "OPPORTUNITY",
  collection: "accounts",
  dataIndex: "accountId",
  title: "Account",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "ACCOUNT",
    cardinality: "one",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const alias = {
  entityType: "OPPORTUNITY",
  dataIndex: "alias",
  title: "Name",
  type: "TEXT",
  mapUiUrl: (value) => `/opportunities/${value}`,
  isSystem: true,
  isRequired: true,
  canMatchEntityBy: true,
  canEdit: true,
};

/** @type {SystemField} */
export const contacts = {
  entityType: "OPPORTUNITY",
  collection: "contacts",
  dataIndex: "contacts",
  title: "Contacts",
  type: "SET",
  relationship: {
    entityType: "CONTACT",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const createdOn = {
  entityType: "OPPORTUNITY",
  dataIndex: "createdOn",
  title: "Added On",
  type: "DATE",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const funnelProgress = {
  entityType: "OPPORTUNITY",
  dataIndex: "funnelProgress",
  title: "Progress",
  type: "PERCENT",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const initialFunnelProgress = {
  entityType: "OPPORTUNITY",
  dataIndex: "initialFunnelProgress",
  title: "Initial Funnel Progress",
  type: "PERCENT",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const initialState = {
  collection: "opportunityStates",
  entityType: "OPPORTUNITY",
  dataIndex: "initialStateId",
  title: "Initial Stage",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "OPPORTUNITY_STATE",
    cardinality: "one",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const lastActedOn = {
  entityType: "OPPORTUNITY",
  dataIndex: "lastActedOn",
  title: "Last Acted On",
  type: "ACTIVITY_DATE",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const lastStateChangeOn = {
  entityType: "OPPORTUNITY",
  dataIndex: "lastStateChangeOn",
  title: "Stage Changed On",
  type: "DATE",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const lossReason = {
  collection: "lossReasons",
  entityType: "OPPORTUNITY",
  dataIndex: "lossReasonId",
  title: "Loss Reason",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "LOSS_REASON",
    cardinality: "one",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const nextFollowup = {
  entityType: "OPPORTUNITY",
  dataIndex: "nextFollowupOn",
  title: "Next Followup",
  type: "FOLLOWUP_DATE",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const partners = {
  collection: "vendors",
  entityType: "OPPORTUNITY",
  dataIndex: "vendors",
  title: "Partners",
  type: "SET",
  relationship: {
    entityType: "VENDOR",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const products = {
  collection: "products",
  entityType: "OPPORTUNITY",
  dataIndex: "products",
  title: "Products",
  type: "SET",
  relationship: {
    entityType: "PRODUCT",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const salesProcess = {
  collection: "salesProcesses",
  entityType: "OPPORTUNITY",
  dataIndex: "salesProcessId",
  title: "Sales Process",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "SALES_PROCESS",
    cardinality: "one",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const startedOn = {
  entityType: "OPPORTUNITY",
  dataIndex: "startedOn",
  title: "Started On",
  type: "DATE",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const state = {
  collection: "opportunityStates",
  entityType: "OPPORTUNITY",
  dataIndex: "stateId",
  title: "Stage",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "OPPORTUNITY_STATE",
    cardinality: "one",
  },
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const summary = {
  entityType: "OPPORTUNITY",
  dataIndex: "summary",
  title: "Summary",
  type: "TEXT",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const tags = {
  collection: "tags",
  entityType: "OPPORTUNITY",
  dataIndex: "tags",
  title: "Tags",
  type: "SET",
  relationship: {
    entityType: "TAG",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const targetWinDate = {
  entityType: "OPPORTUNITY",
  dataIndex: "targetWinDate",
  title: "Target Win Date",
  type: "DATE",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const users = {
  collection: "users",
  entityType: "OPPORTUNITY",
  dataIndex: "users",
  title: "Sales Reps",
  type: "SET",
  relationship: {
    entityType: "USER",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const value = {
  entityType: "OPPORTUNITY",
  dataIndex: "value",
  title: "Value",
  type: "DOLLAR",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const weightedRevenue = {
  entityType: "OPPORTUNITY",
  dataIndex: "weightedRevenue",
  title: "Weighted Revenue",
  type: "DOLLAR",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const wonDate = {
  entityType: "OPPORTUNITY",
  dataIndex: "wonDate",
  title: "Won Date",
  type: "DATE",
  isSystem: true,
  canEdit: false,
};

export const COLLECTION = Object.freeze([
  account,
  alias,
  createdOn,
  contacts,
  funnelProgress,
  initialFunnelProgress,
  initialState,
  lastActedOn,
  lastStateChangeOn,
  lossReason,
  nextFollowup,
  partners,
  products,
  salesProcess,
  startedOn,
  state,
  summary,
  tags,
  targetWinDate,
  users,
  value,
  weightedRevenue,
  wonDate,
]);

export const DEFAULT_FIELDS = Object.freeze([
  "stateId",
  "accountId",
  "contacts",
  "funnelProgress",
  "lastActedOn",
  "nextFollowupOn",
  "value",
]);

export const FIXED_FIELDS = Object.freeze(["alias"]);

export const MAP = Object.freeze(reduceFieldsToMap(COLLECTION));
