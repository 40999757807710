// @ts-check

import {
  accountFields,
  activityFields,
  activityTypeFields,
  contactFields,
  lossReasonFields,
  opportunityFields,
  opportunityStateFields,
  productFields,
  salesProcessFields,
  tagFields,
  userFields,
  vendorFields,
} from "@evolved/views";
import { isSystemField } from "@evolved/domain/src/field";

/** @typedef {import("@evolved/domain").EntityType} EntityType */
/** @typedef {import("@evolved/domain").FieldTypeConfigs} FieldTypeConfigs */
/** @typedef {import("@evolved/domain").FieldType} FieldType */
/** @typedef {import("@evolved/domain").SystemField} SystemField */
/** @typedef {import("@evolved/domain").UserDefinedField} UserDefinedField */

// TODO: add activity import support

/** @type {Partial<Record<import("@evolved/domain").EntityType, Readonly<SystemField[]>>>} */
const fieldsByType = {
  ACCOUNT: accountFields.COLLECTION,
  ACTIVITY: activityFields.COLLECTION,
  ACTIVITY_TYPE: activityTypeFields.COLLECTION,
  CONTACT: contactFields.COLLECTION,
  LOSS_REASON: lossReasonFields.COLLECTION,
  OPPORTUNITY: opportunityFields.COLLECTION,
  OPPORTUNITY_STATE: opportunityStateFields.COLLECTION,
  PRODUCT: productFields.COLLECTION,
  SALES_PROCESS: salesProcessFields.COLLECTION,
  TAG: tagFields.COLLECTION,
  USER: userFields.COLLECTION,
  VENDOR: vendorFields.COLLECTION,
};

/**
 * @param {EntityType} entityType
 */
const filterByEntityType = (entityType) => {
  /**
   * @param {UserDefinedField | SystemField} item
   */
  return (item) => {
    if (isSystemField(item)) {
      return item.entityType === entityType;
    }

    return item.type === entityType;
  };
};

/**
 * Map UserDefinedField to SystemField.
 *
 * @param {SystemField | UserDefinedField} field
 *
 * @returns {import("./domain").ImportField}
 */
const mapToImportField = (field) => {
  if (isSystemField(field)) {
    return {
      ...field,
      name: field.title,
    };
  }

  if (field.dataType === "SELECT") {
    return {
      name: field.name,
      title: field.name,
      type: field.dataType,
      dataIndex: `userDefinedFields.${field.id}`,
      options: field.options,
    };
  }

  if (field.dataType === "TEXT") {
    return {
      name: field.name,
      title: field.name,
      type: field.dataType,
      dataIndex: `userDefinedFields.${field.id}`,
      canMatchEntityBy: true,
    };
  }

  if (field.dataType === "CALCULATED") {
    return {
      name: field.name,
      title: field.name,
      type: field.dataType,
      dataIndex: `userDefinedFields.${field.id}`,
      formula: field.formula,
    };
  }

  return {
    name: field.name,
    title: field.name,
    type: field.dataType,
    dataIndex: `userDefinedFields.${field.id}`,
  };
};

/**
 * @param {Object} o
 * @param {UserDefinedField[]} o.userDefinedFields
 * @param {EntityType} o.entityType
 *
 * @returns {import("./domain").ImportField[]}
 */
export const getImportFields = ({ userDefinedFields, entityType }) => {
  return [
    {
      name: "CRM ID",
      title: "CRM ID",
      dataIndex: "_id",
      type: "TEXT",
      canMatchEntityBy: true,
    },
    ...(fieldsByType[entityType] ?? [])
      .filter(({ canEdit }) => canEdit)
      .map(mapToImportField),
    ...userDefinedFields
      .filter(filterByEntityType(entityType))
      .map(mapToImportField)
      // NOTE: CALCULATED fields are not supported.
      // TODO: Figure out type safety
      .filter((field) => field.type !== "CALCULATED"),
  ];
};
