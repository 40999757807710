// @ts-check

import produce from "immer";

import { CREATE_USER_DEFINED_FIELD_OPTION } from "../identify-headers.jsx";
import { buildMapToIndexes } from "./map-to-indexes";
import { findField as _findField } from "../find-field.js";
import { getPreviousHeaderIndexes } from "./get-previous-header-indexes";
import { reduceColumnOptions } from "../reduce-column-options";
import { reduceState } from "./reduce-state.js";
import { syncMatchConfigDataIndexes } from "./sync-entity-match-config-data-indexes.js";

/**
 * @param {import("../domain").ImportState} state
 * @returns {state is (
 *  Omit<import("../domain").ImportState, 'upload'> & {
 *    upload: import("../domain").CSVUpload
 *  }
 * )}
 */
const ensureUpload = (state) => {
  if (!state.upload) {
    throw new Error("file must be uploaded before this step");
  }

  return !!state.upload;
};

/** @typedef {import("../domain").ImportState} State */

/**
 * @param {Object} o
 * @param {import("../domain").ImportConfig} [o.initialConfig]
 * @param {import("../domain").ImportableEntityTypes} o.entityType
 * @param {import("../use-entity-cache").EntityCache} o.entityCache
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {import("@evolved/domain").UserDefinedField[]} o.userDefinedFields
 */
export const buildImportState = ({ initialConfig, ...params }) => {
  /**
   * @type {State}
   */
  let state = reduceState({
    config: initialConfig ?? {
      headers: { dataIndexes: [] },
      entityMatch: { dataIndexes: [], createIfNoMatch: true },
      relationshipMatches: {},
      newUserDefinedFields: [],
    },
    ...params,
  });

  /**
   * @type {(state: import("../domain").ImportState) => void}
   */
  let onUpdate;

  /**
   * @param {typeof onUpdate} _onUpdate
   */
  const setOnUpdate = (_onUpdate) => {
    onUpdate = _onUpdate;
  };

  /**
   * @type {(error: Error) => void}
   */
  let onError;

  /**
   * @param {typeof onError} _onError
   */
  const setOnError = (_onError) => {
    onError = _onError;
  };

  /**
   * @param {(state: State) => State} update
   */
  const updateState = (update) => {
    try {
      const next = update(state);

      /** @type {Record<string, import("../domain").RelationshipMatchConfig>} */
      const nextRelationshipMatches = {};

      for (const [dataIndex, { config }] of Object.entries(
        next.relationshipMatches
      )) {
        nextRelationshipMatches[dataIndex] = config;
      }

      state = reduceState({
        ...params,
        config: {
          upload: next.upload,
          headers: next.headers.config,
          entityMatch: next.entityMatch.config,
          relationshipMatches: nextRelationshipMatches,
          newUserDefinedFields: next.newUserDefinedFields,
        },
      });

      onUpdate?.(state);
      return state;
    } catch (e) {
      if (onError) {
        onError(e);
      }
    }
  };

  /**
   * @param {import("../domain").CSVUpload} upload
   */
  const setUpload = (upload) => {
    return updateState(
      produce((draft) => {
        const mapToIndexes = buildMapToIndexes(
          getPreviousHeaderIndexes({
            next: upload.headers,
            previous: draft.upload?.headers,
          })
        );

        draft.headers.config.dataIndexes = mapToIndexes(
          draft.headers.config.dataIndexes
        );

        draft.newUserDefinedFields = mapToIndexes(draft.newUserDefinedFields);

        draft.entityMatch.config = {
          createIfNoMatch: true,
          dataIndexes: syncMatchConfigDataIndexes({
            entityType: params.entityType,
            headerDataIndexes: draft.headers.config.dataIndexes,
            matchConfig: draft.entityMatch.config,
          }),
        };

        draft.upload = upload;
      })
    );
  };

  /**
   * @param {Object} o
   * @param {string | null} o.value
   * @param {number} o.index
   */
  const setHeaderDataIndex = ({ value, index }) => {
    updateState(
      produce((draft) => {
        if (!ensureUpload(draft)) {
          return;
        }

        draft.headers.config.dataIndexes[index] = value;

        if (value === CREATE_USER_DEFINED_FIELD_OPTION.value) {
          let next = draft.newUserDefinedFields[index];

          if (!next) {
            next = {
              id: crypto.randomUUID(),
              dataType: "TEXT",
              type: params.entityType,
              name: draft.upload.headers[index],
            };
          }

          if (next.dataType === "SELECT") {
            next = {
              ...next,
              options: reduceColumnOptions({
                generateId: () => crypto.randomUUID(),
                index,
                rows: draft.upload.rows,
              }),
            };
          }

          draft.newUserDefinedFields[index] = next;
        }
      })
    );
  };

  /**
   * @param {Object} o
   * @param {import("@evolved/domain").UserDefinedFieldType} o.type
   * @param {number} o.index
   */
  const setNewUserDefinedFieldType = ({ type, index }) => {
    updateState(
      produce((draft) => {
        if (!ensureUpload(draft)) {
          return;
        }

        if (type === "CALCULATED") {
          throw new Error(
            "CALCULATED UDF data type is not supported by import."
          );
        }

        let next = draft.newUserDefinedFields[index];
        if (!next) {
          throw new Error(
            "new user defined field config should be set during identify headers step."
          );
        }

        if (type === "SELECT") {
          next = {
            ...next,
            dataType: type,
            options: reduceColumnOptions({
              generateId: () => crypto.randomUUID(),
              index,
              rows: draft.upload.rows,
            }),
          };
        } else {
          next = {
            ...next,
            dataType: type,
          };
        }

        draft.newUserDefinedFields[index] = next;
      })
    );
  };

  /**
   * Remove entityMatchConfig.dataIndexes that are not
   * in current headerDataIndexes (selections) by the user.
   */
  const syncEntityMatchConfigDataIndexes = () => {
    updateState(
      produce((draft) => {
        if (!ensureUpload(draft)) {
          return;
        }

        draft.entityMatch.config.dataIndexes = syncMatchConfigDataIndexes({
          entityType: params.entityType,
          headerDataIndexes: draft.headers.config.dataIndexes,
          matchConfig: draft.entityMatch.config,
        });
      })
    );
  };

  const canAddEntityMatchKey = (_state = state) => {
    return _state.entityMatch.config.dataIndexes.length < 5;
  };

  const canRemoveEntityMatchKey = (_state = state) => {
    return _state.entityMatch.config.dataIndexes.length > 1;
  };

  const addEntityMatchKey = () => {
    updateState(
      produce((draft) => {
        if (!canAddEntityMatchKey(draft)) {
          return;
        }

        draft.entityMatch.config.dataIndexes.push([]);
      })
    );
  };

  /**
   * @param {Object} o
   * @param {number} o.index
   */
  const removeEntityMatchKey = ({ index }) => {
    updateState(
      produce((draft) => {
        if (!canRemoveEntityMatchKey(draft)) {
          return;
        }

        draft.entityMatch.config.dataIndexes.splice(index, 1);
      })
    );
  };

  /**
   * @param {Object} o
   * @param {string[]} o.value
   * @param {number} o.index
   */
  const setEntityMatchKey = ({ value, index }) => {
    updateState(
      produce((draft) => {
        if (!draft.entityMatch.config.dataIndexes[index]) {
          throw new Error(
            `attempted to set entity match key at unexisting index ${index}.`
          );
        }

        draft.entityMatch.config.dataIndexes[index] = value;
      })
    );
  };

  /**
   * @param {Object} o
   * @param {boolean} o.value
   */
  const setCreateEntityIfNoMatch = ({ value }) => {
    updateState(
      produce((draft) => {
        draft.entityMatch.config.createIfNoMatch = value;
      })
    );
  };

  const canAddRelationshipMatchKey = (_state = state) => {
    /**
     * @param {string} dataIndex
     */
    const hof = (dataIndex) => {
      if (!_state.relationshipMatches[dataIndex]) {
        return false;
      }

      return (
        (_state.relationshipMatches[dataIndex]?.config.dataIndexes ?? [])
          .length < 5
      );
    };

    return hof;
  };

  const canRemoveRelationshipMatchKey = (_state = state) => {
    /**
     * @param {string} dataIndex
     */
    const hof = (dataIndex) => {
      if (!_state.relationshipMatches[dataIndex]) {
        return false;
      }

      return (
        (_state.relationshipMatches[dataIndex]?.config.dataIndexes ?? [])
          .length > 1
      );
    };

    return hof;
  };

  /**
   * @param {string} dataIndex
   */
  const addRelationshipMatchKey = (dataIndex) => {
    updateState(
      produce((draft) => {
        if (!canAddRelationshipMatchKey(draft)(dataIndex)) {
          return;
        }

        draft.relationshipMatches[dataIndex].config.dataIndexes.push([]);
      })
    );
  };

  /**
   * @param {Object} o
   * @param {string} o.dataIndex
   * @param {number} o.index
   */
  const removeRelationshipMatchKey = ({ dataIndex, index }) => {
    updateState(
      produce((draft) => {
        if (!canRemoveRelationshipMatchKey(draft)(dataIndex)) {
          return;
        }

        draft.relationshipMatches[dataIndex].config.dataIndexes.splice(
          index,
          1
        );
      })
    );
  };
  /**
   * @param {Object} o
   * @param {string[]} o.value
   * @param {string} o.dataIndex
   * @param {number} o.index
   */
  const setRelationshipMatchKey = ({ value, dataIndex, index }) => {
    updateState(
      produce((draft) => {
        if (!draft.relationshipMatches[dataIndex]) {
          throw new Error(
            `relationshipMatch "${dataIndex}" should have been set when "${dataIndex}" was selected as a header mapping.`
          );
        }

        const current =
          draft.relationshipMatches[dataIndex].config.dataIndexes[index];

        if (!current) {
          throw new Error(
            `attempted to set relationship match key at unexisting data index "${dataIndex}" and index = ${index}.`
          );
        }

        draft.relationshipMatches[dataIndex].config.dataIndexes[index] = value;
      })
    );
  };

  /** @param {string} dataIndex */
  const findField = (dataIndex) => {
    return _findField(dataIndex)(params.importFields);
  };

  const getState = () => {
    return state;
  };

  return {
    getState,
    setOnError,
    setOnUpdate,

    addEntityMatchKey,
    addRelationshipMatchKey,
    canAddEntityMatchKey,
    canAddRelationshipMatchKey,
    canRemoveEntityMatchKey,
    canRemoveRelationshipMatchKey,
    removeEntityMatchKey,
    removeRelationshipMatchKey,
    setCreateEntityIfNoMatch,
    setEntityMatchKey,
    setHeaderDataIndex,
    setNewUserDefinedFieldType,
    setRelationshipMatchKey,
    setUpload,
    syncEntityMatchConfigDataIndexes,

    findField,
  };
};

/** @typedef {ReturnType<typeof buildImportState>} ImportStateService */
