// @ts-check

/**
 * @param {(number | null)[]} indexes
 */
export const buildMapToIndexes = (indexes) => {
  /**
   * @template T
   * @param {readonly (T | null)[]} values
   * @returns {(T | null)[]}
   */
  const fn = (values) => {
    return indexes.map((index) => {
      if (index === null) {
        return null;
      }

      return values[index];
    });
  };

  return fn;
};
