// @ts-check

import { CREATE_USER_DEFINED_FIELD_OPTION } from "../identify-headers";
import { areAllRequiredFieldsSelected } from "../configure-matches/are-all-required-fields-selected";
import { filterSelectedUdfs } from "../filter-selected-new-udfs";
import { findField } from "../find-field";
import { getDataIndexOptions } from "../configure-matches/get-data-index-options";
import { getDefaultDataIndexes } from "../configure-matches/get-default-data-indexes";
import { getDefaultHeaderDataIndexes } from "../get-default-header-data-indexes";
import { getDuplicateHeaders } from "../get-duplicate-headers";
import { getHeaderOptions } from "./get-header-options";
import { getImportFields } from "../get-import-fields";
import { getRequiredFields } from "../configure-matches/get-required-fields";
import { isMatchableField } from "../configure-matches/is-matchable-field";
import { reduceEntitiesByMatchKeys } from "../configure-matches/get-entity-matches";
import { reduceEntityMatchReport } from "../configure-matches/reduce-entity-match-report";
import { reduceImportEntities } from "../reduce-import-entities";
import { reduceRelationshipMatchReport } from "../configure-matches/reduce-relationship-match-report";
import {
  getUnconfiguredRelationships,
  isRelationshipDataIndex,
} from "../get-unconfigured-relationships";
import {
  reduceRowsByMatchKeys,
  reduceRowsByMultiMatchKeys,
} from "../configure-matches/get-row-matches";

/**
 * Reduces the current state to determine valid
 * states for the user to navigate to.
 *
 * If user state has not specified a stepId,
 * then push the user to the last state.
 *
 * @param {Object} o
 * @param {import("../domain").ImportConfig} [o.config]
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {import("@evolved/domain").UserDefinedField[]} o.userDefinedFields
 * @param {import("../domain").ImportableEntityTypes} o.entityType
 * @param {import("../use-entity-cache").EntityCache} o.entityCache
 *
 * @returns {import("../domain").ImportState}
 *
 */
export const reduceState = ({
  config = {
    headers: { dataIndexes: [] },
    entityMatch: { dataIndexes: [], createIfNoMatch: true },
    relationshipMatches: {},
    newUserDefinedFields: [],
  },
  importFields,
  userDefinedFields,
  entityType,
  entityCache,
}) => {
  /** @type {import("../domain").CSVUpload} */
  const upload = config.upload ?? { headers: [], rows: [] };

  const duplicateHeaders = getDuplicateHeaders(upload.headers);

  // TODO: rename getDirectMatchDataIndexes
  const directMatchDataIndexes = getDefaultHeaderDataIndexes({
    importFields,
    headers: upload.headers,
  });

  const headerDataIndexes = config.headers.dataIndexes.map(
    (dataIndex, index) => {
      return directMatchDataIndexes[index] ?? dataIndex;
    }
  );

  const headerOptions = getHeaderOptions({
    headerDataIndexes,
    importFields,
  }).map((options, index) => {
    // NOTE: If there is a direct match, don't
    // give any options, don't want to give an
    // opportunity for a mistake here.
    //
    // User can change the name in the upload
    // data if it's different.
    if (directMatchDataIndexes[index]) {
      return null;
    }

    return options;
  });

  const entitiesByMatchKeys = reduceEntitiesByMatchKeys({
    entities: entityCache[entityType],
    dataIndexes: config.entityMatch.dataIndexes,
  });

  const rowsByMatchKeys = reduceRowsByMatchKeys({
    dataIndexes: config.entityMatch.dataIndexes,
    headerDataIndexes,
    rows: upload.rows,
  });

  const requiredFields = getRequiredFields({ importFields });
  const canCreateIfNoMatch = areAllRequiredFieldsSelected({
    importFields,
    headerDataIndexes,
  });

  /**
   * @type {Record<string, {label: string; value: string;}[]>}
   */
  const relationshipMatchOptions = {};

  /**
   * @type {Record<string, {
   *  entitiesByMatchKeys: Record<string, string[]>;
   *  rowsByMatchKeys: Record<string, number[]>;
   * }>}
   */
  const relationshipMatchKeys = {};

  const relationshipDataIndexes = headerDataIndexes.filter(
    isRelationshipDataIndex(importFields)
  );

  const relationshipMatchConfigs = {
    ...config.relationshipMatches,
  };

  for (const dataIndex of relationshipDataIndexes) {
    const field = findField(dataIndex)(importFields);

    if (field.type !== "SET") {
      throw new Error("relationship field should be SET");
    }

    const relationshipImportFields = getImportFields({
      entityType: field.relationship.entityType,
      userDefinedFields,
    });

    const matchableFields = relationshipImportFields.filter(isMatchableField);

    relationshipMatchOptions[dataIndex] = matchableFields.map(
      ({ dataIndex, name }) => {
        return {
          value: dataIndex,
          label: name,
        };
      }
    );

    if (!relationshipMatchConfigs[dataIndex]) {
      // NOTE: apply default dataIndexes if nothing yet configured.
      relationshipMatchConfigs[dataIndex] = {
        dataIndexes: getDefaultDataIndexes({
          entityType: field.relationship.entityType,
        }),
      };
    }

    const relationshipMatchConfig = relationshipMatchConfigs[dataIndex];

    if (!relationshipMatchConfig) {
      throw new Error(
        `relationshipMatchConfig should have been initialized for dataIndex = "${dataIndex}"`
      );
    }

    const entitiesByMatchKeys = relationshipMatchConfig.dataIndexes.flat()
      .length
      ? reduceEntitiesByMatchKeys({
          entities: entityCache[field.relationship.entityType],
          dataIndexes: relationshipMatchConfig.dataIndexes,
        })
      : {};

    const rowsByMatchKeys = relationshipMatchConfig.dataIndexes.flat().length
      ? reduceRowsByMultiMatchKeys({
          cardinality: field.relationship.cardinality,
          dataIndex: field.dataIndex,
          headerDataIndexes,
          rows: upload.rows,
        })
      : {};

    relationshipMatchKeys[dataIndex] = {
      entitiesByMatchKeys,
      rowsByMatchKeys,
    };
  }

  // TODO:
  // - do we need to use canCreateIfNoMatch to override
  // entityMatchConfig.createIfNoMatch setting,
  // or does reduceImportEntities already do this?
  const reducedRows = reduceImportEntities({
    importFields: getImportFields({
      userDefinedFields: [
        ...userDefinedFields,
        ...filterSelectedUdfs(config.newUserDefinedFields),
      ],
      entityType,
    }),
    entityType,
    entityCache: entityCache[entityType],
    upload,
    headerDataIndexes: headerDataIndexes.map((dataIndex, index) => {
      if (dataIndex === CREATE_USER_DEFINED_FIELD_OPTION.value) {
        const newUdf = config.newUserDefinedFields?.[index];

        if (!newUdf) {
          throw new Error(
            `Something went wrong. Creating user defined field for column ${
              index + 1
            } failed.`
          );
        }

        return `userDefinedFields.${newUdf.id}`;
      }

      return dataIndex;
    }),
    entityMatchConfig: config.entityMatch,
    entityMatchKeys: {
      entitiesByMatchKeys,
      rowsByMatchKeys,
    },
    relationshipMatchKeys,
  });

  /** @type {Record<string, import("../domain").RelationshipMatchState>}*/
  const relationshipMatchesState = {};

  for (const dataIndex of relationshipDataIndexes) {
    relationshipMatchesState[dataIndex] = {
      config: relationshipMatchConfigs[dataIndex],
      dataIndexOptions: relationshipMatchOptions[dataIndex],
      report: reduceRelationshipMatchReport({
        dataIndex,
        reducedRows,
      }),
    };
  }

  /** @type {import("../domain").ImportState} */
  const nextState = {
    upload: config.upload,
    headers: {
      config: {
        dataIndexes: headerDataIndexes,
      },
      duplicateHeaders,
      dataIndexOptions: headerOptions,
    },
    entityMatch: {
      dataIndexOptions: getDataIndexOptions({
        headerDataIndexes,
        importFields,
      }),
      config: config.entityMatch,
      // TODO: consider building off of reducedRows to better ensure
      // what is being confirmed is what is being reported.
      report: reduceEntityMatchReport({
        entitiesByMatchKeys,
        rowsByMatchKeys,
        rows: upload.rows,
      }),
      requiredFields,
      canCreateIfNoMatch,
    },
    // TODO: update above to expect this
    relationshipMatches: relationshipMatchesState,
    newUserDefinedFields: config.newUserDefinedFields,
    reducedRows,
    validStepIds: ["upload_file"],
  };

  if (
    !config.upload ||
    !config.upload.headers.length ||
    !config.upload.rows.length ||
    duplicateHeaders.length
  ) {
    return nextState;
  }

  nextState.validStepIds.push("configure_header_data_indexes");

  // NOTE: At least one selection should have been made.
  if (!headerDataIndexes.filter((v) => !!v).length) {
    return nextState;
  }

  if (
    headerDataIndexes.some(
      (dataIndex) => dataIndex === CREATE_USER_DEFINED_FIELD_OPTION.value
    )
  ) {
    nextState.validStepIds.push("create_user_defined_fields");
  }

  if (nextState.entityMatch.dataIndexOptions.length) {
    nextState.validStepIds.push("configure_entity_match");
  }

  // NOTE: configured to not do anything with import
  // rows. Must be able to update or create.
  if (
    !config.entityMatch.dataIndexes.some((dataIndexes) => dataIndexes.length) &&
    (!config.entityMatch.createIfNoMatch ||
      !areAllRequiredFieldsSelected({
        importFields,
        headerDataIndexes,
      }))
  ) {
    // TODO: do we need a better error?
    return nextState;
  }

  if (relationshipDataIndexes.length) {
    nextState.validStepIds.push("configure_relationships_matches");

    const unconfiguredRelationships = getUnconfiguredRelationships({
      importFields,
      relationshipMatchConfigs,
      headerDataIndexes,
    });

    if (unconfiguredRelationships.filter((v) => !!v).length) {
      return nextState;
    }
  }

  nextState.validStepIds.push("confirm");

  return nextState;
};
