// @ts-check

import moment from "moment";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import get from "lodash/get";
import set from "lodash/set";

import { findField } from "./find-field";
import { getRowMatchKeys } from "./configure-matches/get-row-matches";
import { getSelectFieldOptionsCache } from "./get-select-field-options-cache";
import { mergeEntityWithPrevious } from "./merge-entity-with-previous";
import { normalize } from "./are-headers-equal";
import { splitMultiMatchKeys } from "./configure-matches/get-relationship-match-keys";
import { validateByType } from "./entity-validation";

/**
 * @typedef {import("@evolved/domain").FieldType} FieldType
 */

/**
 * @param {string} value
 */
const parseDate = (value) => {
  const parsed = moment(value);

  if (!parsed.isValid()) {
    return {
      error: "Cannot parse date value. Try a format such as 1/30/2021 13:00:00",
    };
  }

  return {
    value: parsed.unix(),
  };
};

/**
 * @type {{
 *   [K in FieldType]?: (value: string) => ({value: any} | {error: string})
 * }}
 */
const conversions = {
  ACTIVITY_DATE: parseDate,
  DOLLAR: (value) => {
    if (!value) {
      return { value: undefined };
    }

    const parsed = parseFloat(value.replace(/,|\s|\$/g, ""));

    if (!isFinite(parsed)) {
      return {
        error:
          "Cannot parse value to dollar. Valid Examples: [100, $100, $100,100.10]",
      };
    }

    return { value: parsed };
  },
  DATE: parseDate,
  FOLLOWUP_DATE: parseDate,
  PERCENT: (value) => {
    let parsed = Number(value.replace("%", ""));

    if (!isFinite(parsed)) {
      return {
        error: `Cannot parse percent value. Must be a percentage between 0 and 100`,
      };
    }

    // TODO: I don't think we can make this assumption
    // if (parsed < 1) {
    //   parsed = parsed * 100;
    // }

    if (parsed < 0 || parsed > 100) {
      return {
        error: `Cannot parse percent value. Must be a percentage between 0 and 100`,
      };
    }

    return {
      value: parsed,
    };
  },
};

/**
 * @param {Object} o
 * @param {import("./domain").ImportableEntityTypes} o.entityType
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {any[]} o.entityCache
 * @param {import("./domain").CSVUpload} o.upload
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {import("./domain").EntityMatchConfig} o.entityMatchConfig
 *
 * @param {{
 *  entitiesByMatchKeys: Record<string, string[]>;
 *  rowsByMatchKeys: Record<string, number[]>;
 * }} o.entityMatchKeys
 *
 * @param {Record<string, {
 *  entitiesByMatchKeys: Record<string, string[]>;
 * }>} o.relationshipMatchKeys
 *
 * @returns {import("./domain").ReducedRow[]}
 */

export const reduceImportEntities = ({
  // NOTE: importFields should have been reduced with newUserDefinedFields already
  entityType,
  importFields,
  entityCache,
  upload,
  headerDataIndexes,
  entityMatchConfig,
  entityMatchKeys,
  relationshipMatchKeys,
}) => {
  const selectFieldOptionsCache = getSelectFieldOptionsCache({
    headerDataIndexes,
    importFields,
  });

  const { entitiesByMatchKeys, rowsByMatchKeys } = entityMatchKeys;

  // TODO:
  // - validate that rows to be created have all
  // required fields
  // - validate all fields in general to ensure they
  // are valid (and won't fail on server side)

  /**
   * @param {string[]} row
   *
   * @returns {import("./domain").ReducedRow}
   */
  const reducer = (row) => {
    /** @type {import("./domain").ReducedRow} */
    const reducedRow = {
      entity: {
        userDefinedFields: {},
      },
      info: [],
      // NOTE: true until proven false
      isValid: true,
    };

    const rowMatchKeys = getRowMatchKeys({
      row,
      headerDataIndexes,
      dataIndexes: entityMatchConfig.dataIndexes,
    });

    /**
     * @type {import("./domain").EntityMatchKeyInfo[]}
     */
    const entityMatches = rowMatchKeys.map((matchKey) => {
      const rowIndexes = rowsByMatchKeys[matchKey] ?? [];
      const entityIds = entitiesByMatchKeys[matchKey] ?? [];

      if (rowIndexes.length > 1 || entityIds.length > 1) {
        return {
          matchKey,
          duplicates: {
            rowIndexes,
            entityIds,
          },
        };
      }

      const entityId = entityIds[0];

      return {
        matchKey,
        entityId,
      };
    });

    /**
     * @param {import("./domain").EntityMatchKeyInfo} info
     *
     * @returns {info is {rowIndexes: number[]; entityIds: string[];}}
     */
    const isInvalidEntityMatchKeyInfo = (info) => {
      return "duplicates" in info;
    };

    const invalidMatches = entityMatches.filter(isInvalidEntityMatchKeyInfo);

    /**
     * @param {import("./domain").EntityMatchKeyInfo} info
     *
     * @returns {info is {matchKey: string; entityId: string;}}
     */
    const isMatchedEntityMatchKeyInfo = (info) => {
      return !("duplicates" in info) && !!info.entityId;
    };

    const validMatchEntityIds = [
      ...new Set(
        entityMatches.filter(isMatchedEntityMatchKeyInfo).map((info) => {
          return info.entityId;
        })
      ),
    ];

    if (
      validMatchEntityIds.length > 1 ||
      (!validMatchEntityIds.length && invalidMatches.length)
    ) {
      reducedRow.info.push({
        type: "entity_match_info",
        level: "error",
        matchKeys: entityMatches,
      });
    } else {
      reducedRow.info.push({
        type: "entity_match_info",
        level: "info",
        matchKeys: entityMatches,
        ...(validMatchEntityIds.length === 0
          ? {
              entityId: validMatchEntityIds[0],
            }
          : {}),
      });
    }

    if (validMatchEntityIds.length === 1) {
      // TODO: if we do merge for relationships...
      // - will need some logic here to properly merge
      reducedRow.entity._id = validMatchEntityIds[0];
      // TODO: performance test this
      reducedRow.previous = entityCache.find((entity) => {
        return entity._id === validMatchEntityIds[0];
      });

      if (!reducedRow.previous) {
        throw new Error(
          `Failed to find entity with id "${validMatchEntityIds[0]}"`
        );
      }
    }

    for (const [index, dataIndex] of Object.entries(headerDataIndexes)) {
      if (!dataIndex) {
        continue;
      }

      const raw = row[Number(index)];
      const field = findField(dataIndex)(importFields);

      const parsed = (
        conversions[field.type] ??
        (() => ({
          value: raw,
        }))
      )(raw);

      if ("error" in parsed) {
        reducedRow.info.push({
          type: "value_conversion_error",
          level: "warning",
          headerIndex: index,
          error: parsed.error,
        });
        continue;
      }

      if (field.type === "SELECT") {
        // TODO: update user defined fields with new select
        // values. Will need to update view new user defined
        // fields maybe? Or a specific payload?
        const options = selectFieldOptionsCache[Number(index)];

        if (!options) {
          throw new Error(
            `Select field at headerIndex = ${index} has no options.`
          );
        }

        const id = options[normalize(parsed.value)];

        // NOTE: all values should have a normalized
        // option by now, or, if not, the user chose to
        // ignore that value.
        if (!id) {
          continue;
        }

        set(reducedRow.entity, dataIndex, id);
      } else if (field.type === "TEXT") {
        // TODO: validate length?
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "SET") {
        const matchKeys = splitMultiMatchKeys({
          value: parsed.value,
        });

        if (field.relationship.cardinality === "one" && matchKeys.length > 1) {
          reducedRow.info.push({
            type: "relationship_match_info",
            level: "warning",
            dataIndex,
            headerIndex: Number(index),
            cardinalityOverflow: true,
          });
          continue;
        }

        /**
         * @type {{
         *  matchKey: string;
         *  entityId: string;
         * }[]}
         */
        const matches = [];

        /**
         * @type {{
         *  matchKey: string;
         * }[]}
         */
        const unmatched = [];

        /**
         * @type {{
         *  matchKey: string;
         *  entityIds: string[];
         * }[]}
         */
        const duplicates = [];

        for (const key of matchKeys) {
          const potentialIds =
            relationshipMatchKeys[dataIndex]?.entitiesByMatchKeys?.[key];

          if (!potentialIds || !potentialIds.length) {
            unmatched.push({
              matchKey: key,
            });
            continue;
          }

          if (potentialIds.length > 1) {
            duplicates.push({
              matchKey: key,
              entityIds: potentialIds,
            });
            continue;
          }

          matches.push({
            matchKey: key,
            entityId: potentialIds[0],
          });
        }

        if (
          matchKeys.length &&
          !matches.length &&
          (field.relationship.cardinality === "one"
            ? !!get(reducedRow.previous, dataIndex)
            : !isEmpty(get(reducedRow.previous, dataIndex)))
        ) {
          reducedRow.info.push({
            type: "relationship_match_info",
            level: "warning",
            dataIndex,
            headerIndex: Number(index),
            willOverwriteWithNothing: true,
          });
          continue;
        }

        if (unmatched.length || duplicates.length) {
          reducedRow.info.push({
            type: "relationship_match_info",
            level: "warning",
            dataIndex,
            headerIndex: Number(index),
            matches,
            unmatched,
            duplicates,
          });
        } else {
          reducedRow.info.push({
            type: "relationship_match_info",
            level: "info",
            dataIndex,
            headerIndex: Number(index),
            matches,
          });
        }

        /** @type {string[]} */
        const _ids = matches.map(({ entityId }) => entityId);

        set(
          reducedRow.entity,
          dataIndex,
          field.relationship.cardinality === "one" ? _ids[0] ?? null : _ids
        );
      } else if (field.type === "LINK") {
        // TODO: validate
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "DATE") {
        // TODO: validate and map?
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "RANGE") {
        // TODO: implement
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "DOLLAR") {
        // TODO: validate and map
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "NUMBER") {
        // TODO: implement
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "PERCENT") {
        // TODO: implement
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "ACCOUNT_STATE") {
        // TODO: implement
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "ACTIVITY_DATE") {
        // TODO: implement
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "FOLLOWUP_DATE") {
        // TODO: implement
        set(reducedRow.entity, dataIndex, parsed.value);
      } else if (field.type === "CALCULATED") {
        // TODO: implement
        set(reducedRow.entity, dataIndex, parsed.value);
      }
    }

    if (
      // NOTE: no matches, so attempting to create
      !validMatchEntityIds.length &&
      // NOTE: if any fields are missing,
      // should be logged as entity_validation_error
      !entityMatchConfig.createIfNoMatch
    ) {
      reducedRow.isValid = false;
    }

    // NOTE: it is possible something changed server side,
    // but unlikely, and that will fail, but this gives us
    // a best shot to catch the errors early.
    const validationErrors = validateByType[entityType](
      mergeEntityWithPrevious(reducedRow)
    );
    if (!("success" in validationErrors)) {
      reducedRow.info.push({
        type: "entity_validation_failed",
        level: "error",
        errors: validationErrors.errors,
      });
    }

    if (
      reducedRow.info.some(({ level }) => {
        return level === "error";
      })
    ) {
      reducedRow.isValid = false;
    }

    return reducedRow;
  };

  return upload.rows.map(reducer);
};
