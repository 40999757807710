import { useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities.js";
import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";
import { root as activitiesRoot } from "./use-activities.js";
import { root as accountsRoot } from "./use-accounts.js";
import { root as opportunitiesRoot } from "./use-opportunities";
import { root as organizationRoot } from "./use-organization.js";
import { root as vendorsRoot } from "./use-vendors.js";

export const root = "/contact";

export const useContacts = buildUseEntities(root);

export const useCreateContact = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(root)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
      },
    },
    overrides
  );
};

export const useCreateContactBatch = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/import`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
      },
    },
    overrides
  );
};

export const useImportContactData = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/import/v2`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [organizationRoot] });
      },
    },
    overrides
  );
};

export const useMergeContacts = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/merge`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [activitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [opportunitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
      },
    },
    overrides
  );
};

export const useUpdateContact = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.patch(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
      },
    },
    overrides
  );
};

export const usePatchContact = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, ...data }) => {
        return fetcher.patch(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
      },
    },
    overrides
  );
};

export const useSetContactTags = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}/tags`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetContactUsers = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}/users`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useTagContact = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/tag`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUntagContact = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/untag`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useArchiveContact = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/archive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUnarchiveContact = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/unarchive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
