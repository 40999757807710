// @ts-check

import { CREATE_USER_DEFINED_FIELD_OPTION } from "../identify-headers";

/**
 * @param {Object} o
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {import("../domain").ImportField[]} o.importFields
 *
 * @returns {{value: string; label: string;}[][]}
 */
export const getHeaderOptions = ({ headerDataIndexes, importFields }) => {
  /**
   * @param {number} index
   *
   * @returns {{value: string; label: string;}[]}
   */
  const getIndexOptions = (index) => {
    return [
      CREATE_USER_DEFINED_FIELD_OPTION,
      ...importFields
        .filter(({ dataIndex }) => {
          return !headerDataIndexes.some(
            (existing, i) => index !== i && existing === dataIndex
          );
        })
        .map(({ dataIndex, name }) => ({
          value: dataIndex,
          label: name,
        })),
    ];
  };

  return headerDataIndexes.map((_, index) => {
    return getIndexOptions(index);
  });
};
