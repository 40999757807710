// @ts-check

import { Button, Select } from "antd";
import React from "react";
import { fieldTypes } from "@evolved/constants";

/** @satisfies {Readonly<import("@evolved/domain").UserDefinedFieldType[]>} */
export const CREATABLE_FIELD_TYPES = Object.freeze([
  "SELECT",
  "TEXT",
  "DATE",
  "NUMBER",
]);
/**
 * @typedef {typeof CREATABLE_FIELD_TYPES[number]} CreatableFieldType
 */

/** 
 * @typedef {Object} Props
 *
 * @prop {import("./import-state/import-state").ImportStateService} service
 * @prop {import("./domain").ImportState} state
 * 
 * @prop {() => void} onBack
 * @prop {(() => void) | null} onNext
 */

/**
 * @type {React.FC<Props>}
 */
export const ConfigureUdfs = ({ service, state, onBack, onNext }) => {
  const upload = state.upload;
  if (!upload) {
    throw new Error("file must be uploaded before this step.");
  }

  return (
    <>
      <div className="mb-4 flex font-bold">
        <div>Name</div>
        <div className="ml-auto">Create</div>
      </div>
      {state.newUserDefinedFields.map((udf, index) => {
        if (!udf) {
          return null;
        }

        return (
          <div className="mb-4 flex" key={udf.id}>
            <div>{upload.headers[index]}</div>
            <div className="ml-auto flex items-start space-x-4">
              <div>
                <Select
                  value={udf.dataType}
                  onChange={(type) => {
                    service.setType({ index, type })
                  }}
                  options={[
                    {
                      value: fieldTypes.TEXT,
                      label: "Text",
                    },
                    {
                      value: fieldTypes.SELECT,
                      label: "Select",
                    },
                    {
                      value: fieldTypes.DATE,
                      label: "Date",
                    },
                  ]}
                  style={{ width: "120px" }}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>Back</Button>
        <Button type="primary" disabled={!onNext} onClick={onNext ?? undefined}
        >Next</Button>
      </div>
    </>
  );
};
