import Fuse from "fuse.js";

import { debounce, get, intersects, isArray, select } from "radash";
import { useCallback } from "react";

import { fieldTypes } from "@evolved/constants";

import { useOrganization } from "../data/use-organization";
import { useSearchStore } from "../stores/search";
import { useViewStore } from "../stores/view";

export const reduceSearchResults = (ids) => (entitiesStore) => {
  return ids.reduce((accumulator, id) => {
    if (entitiesStore.byId(id)) {
      accumulator.push(entitiesStore.byId(id));
    }

    return accumulator;
  }, []);
};

export const filterConfigKeys = (fieldConfigs) => {
  return select(
    Object.values(fieldConfigs),
    ({ dataIndex }) => dataIndex,
    ({ type }) => [fieldTypes.TEXT, fieldTypes.LINK].includes(type)
  );
};

export const filterUserDefinedSelects = (viewType) => (userDefinedFields) => {
  return userDefinedFields.filter(
    ({ type, dataType }) => type === viewType && dataType === fieldTypes.SELECT
  );
};

export const useSearch = ({ primary, secondary }, viewType) => {
  const organization = useOrganization();

  const debouncedSearch = useCallback(
    debounce({ delay: 250 }, (query) => {
      if (!query) {
        useSearchStore.getState().reset(viewType)();
        return;
      }

      const secondaryHits = [];

      for (const field of filterUserDefinedSelects(viewType)(
        organization.userDefinedFields
      )) {
        const ids = [];

        for (const option of field.options) {
          if (option.label.toLowerCase().includes(query.toLowerCase())) {
            ids.push(option.id);
          }
        }

        if (ids.length) {
          secondaryHits.push({
            ids,
            path: `userDefinedFields.${field.id}`,
          });
        }
      }

      for (const { path, items, keys } of secondary) {
        secondaryHits.push({
          ids: new Fuse(items, {
            shouldSort: false,
            threshold: 0.2,
            keys,
          })
            .search(query)
            .map(({ item }) => item._id),
          path,
        });
      }

      const result = new Set();

      for (const item of primary.items) {
        for (const { path, ids } of secondaryHits) {
          const value = get(item, path);

          if (
            (isArray(value) && intersects(value, ids)) ||
            (!isArray(value) && ids.includes(value))
          ) {
            result.add(item._id);
          }
        }
      }

      new Fuse(primary.items, {
        shouldSort: false,
        threshold: 0.25,
        ignoreLocation: true,
        keys: primary.keys,
      })
        .search(query)
        .forEach(({ item }) => result.add(item._id));

      useSearchStore.getState().setResult(viewType)(Array.from(result));

      if (useViewStore.getState()[viewType].page > 1) {
        useViewStore.getState().setPagination(viewType)({ page: 1 });
      }
    }),
    [primary, ...secondary]
  );

  return debouncedSearch;
};
