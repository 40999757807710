// @ts-check

import React, { useState } from "react";
import isArray from "lodash/isArray";
import { Button, Collapse } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import { ConfigureRelationshipMatch } from "./configure-relationship-match";
import { colors } from "@evolved/constants";
import { findField } from "../find-field";

/**
 * @typedef {Object} Props
 *
 * @prop {import("../domain").ImportField[]} importFields
 * @prop {import("../import-state/import-state").ImportStateService} service
 * @prop {import("../domain").ImportState} state
 *
 * @prop {() => void} onBack
 * @prop {(() => void) | null} onNext
 */

/**
 * @param {Props} props
 */
export const ConfigureRelationshipMatches = (props) => {
  const {
    importFields,
    service,
    state,
    onNext,
    onBack,
  } = props;

  const {
    relationshipMatches,
  } = state;

  const dataIndexes = Object.keys(relationshipMatches).sort();

  const defaultIndex = dataIndexes.find(dataIndex => {
    return relationshipMatches[dataIndex].config.dataIndexes.length === 0;
  }) ?? dataIndexes[0];

  const [currentDataIndex, setCurrentDataIndex] = useState(
    defaultIndex
  );

  /** @type{import("antd").CollapseProps["items"]} */
  const options = dataIndexes.map((dataIndex) => {
    const { config, dataIndexOptions, report } = relationshipMatches[dataIndex];

    const hasSelections = !!config.dataIndexes.flat().length;
    const unmatched = Object.keys(report.unmatched);
    const matchedDuplicates = Object.keys(report.matched.duplicate);
    const validMatched = Object.keys(report.matched.valid);

    /** @type {"success" | "warning"} */
    let level = "success";

    if (!hasSelections || unmatched.length || !validMatched.length || matchedDuplicates.length) {
      level = "warning";
    }

    const icon = level === "success" ?
      <CheckCircleTwoTone style={{ marginLeft: "4px" }} twoToneColor={colors.SUCCESS} /> :
      <CloseCircleTwoTone style={{ marginLeft: "4px" }} twoToneColor={colors.URGENT} />
      ;

    return {
      key: dataIndex,
      label: <div>{findField(dataIndex)(importFields).name}{icon}</div>,
      children: (
        <ConfigureRelationshipMatch
          {...{
            dataIndexOptions,
            relationshipField: findField(dataIndex)(importFields),
            relationshipMatchConfig: config,
            relationshipMatchReport: report,
            service,
          }}
        />
      )
    };
  });

  return (
    <>
      <div className="mb-1">
        Which field(s) on the related entity match CRM records to your import rows?
      </div>
      <div className="mb-2">
        <strong>Unmatched relationships will be ignored.</strong>
      </div>
      <div className="mb-4">
        <Collapse
          accordion
          activeKey={currentDataIndex}
          items={options}
          onChange={(dataIndex) => {
            setCurrentDataIndex(isArray(dataIndex) ? dataIndex[0] : dataIndex);
          }}
          size="small"
        />
      </div>
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>Back</Button>
        <Button type="primary" disabled={!onNext} onClick={onNext ?? undefined}
        >Next</Button>
      </div>
    </>
  );
};
