// @ts-check

import isEmpty from "lodash/isEmpty";

/**
 * @param {object} activity
 * @returns {{success: true} | {errors: string[]}}
 */
export const validateActivity = (activity) => {
  const {
    accountId,
    contacts,
    opportunityId,
    performedOn,
    summary,
    typeId,
    users,
    vendors,
  } = activity;

  /** @type {string[]} */
  const errors = [];

  if (!performedOn) {
    errors.push("Performed on date is required.");
  }

  if (!typeId) {
    errors.push("Must be associated with an activity type.");
  }

  if (!summary) {
    errors.push("Summary is required.");
  }

  if (isEmpty(users)) {
    errors.push("At least 1 sales rep is required.");
  }

  if (!accountId && !opportunityId && isEmpty(contacts) && isEmpty(vendors)) {
    errors.push(
      "Must be associated with an account, opportunity, contact, or partner."
    );
  }

  return isEmpty(errors) ? { success: true } : { errors };
};

/**
 * @param {object} fields
 * @returns {{success: true} | {errors: string[]}}
 */
export const validateContact = (fields) => {
  const { email, firstName, lastName } = fields;

  const errors = [];

  if (!email && !firstName && !lastName) {
    errors.push("Contact must have one of first name, last name or email.");
  }

  return isEmpty(errors) ? { success: true } : { errors };
};

/**
 * @param {object} fields
 * @returns {{success: true} | {errors: string[]}}
 */
export const validateVendor = (fields) => {
  const { name } = fields;

  const errors = [];

  if (!name) {
    errors.push("Vendors must have a name.");
  }

  return isEmpty(errors) ? { success: true } : { errors };
};

/**
 * @param {object} fields
 * @returns {{success: true} | {errors: string[]}}
 */
export const validateAccount = (fields) => {
  const { name } = fields;

  const errors = [];

  if (!name) {
    errors.push("Accounts must have a name.");
  }

  return isEmpty(errors) ? { success: true } : { errors };
};

/**
 * @param {object} fields
 * @returns {{success: true} | {errors: string[]}}
 */
export const validateOpportunity = (fields) => {
  const { alias } = fields;

  const errors = [];

  if (!alias) {
    errors.push("Opportunities must have a name.");
  }

  return isEmpty(errors) ? { success: true } : { errors };
};

/**
 * @type {Record<import("./domain").ImportableEntityTypes, (entity: object) => ({success: true} | {errors: string[]})>
 * }
 */
export const validateByType = {
  ACTIVITY: validateActivity,
  CONTACT: validateContact,
  VENDOR: validateVendor,
  ACCOUNT: validateAccount,
  OPPORTUNITY: validateOpportunity,
};
