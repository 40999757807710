// @ts-check

import { useAccounts } from "../../../data/use-accounts";
import { useAllActivities } from "../../../data/use-activities";
import { useContacts } from "../../../data/use-contacts";
import { useOpportunityStates } from "../../../data/use-opportunity-states";
import { useProducts } from "../../../data/use-products";
import { useSalesProcesses } from "../../../data/use-sales-processes";
import { useVendors } from "../../../data/use-vendors";
import { useOpportunities } from "../../../data/use-opportunities";
import { useActivityTypes } from "../../../data/use-activity-types";
import { useLossReasons } from "../../../data/use-loss-reasons";
import { useUsers } from "../../../data/use-users";

/**
 * @param {import("@evolved/domain").EntityType} entityType
 *
 * @returns {object[]}
 *
 **/
const stubEntities = (entityType) => {
  console.warn(`${entityType} not yet supported in entity cache.`);

  return [];
};

/** @typedef {Record<import("@evolved/domain").EntityType, object[]>} EntityCache

/**
 * @param {Object} [o]
 * @param {boolean} [o.useActivities]
 * @returns {EntityCache}
 **/
export const useEntityCache = (options = { useActivities: false }) => {
  return {
    ACCOUNT: useAccounts().all(),
    ACTIVITY: useAllActivities({ enabled: !!options.useActivities }).all(),
    ACTIVITY_TYPE: useActivityTypes().all(),
    CONTACT: useContacts().all(),
    LOSS_REASON: useLossReasons().all(),
    OPPORTUNITY: useOpportunities().all(),
    OPPORTUNITY_STATE: useOpportunityStates().all(),
    PRODUCT: useProducts().all(),
    SALES_PROCESS: useSalesProcesses().all(),
    USER: useUsers().all(),
    VENDOR: useVendors().all(),
    get ACTIVITY_STEP_IMPACT() {
      return stubEntities("ACTIVITY_STEP_IMPACT");
    },
    get FOLLOWUP() {
      return stubEntities("FOLLOWUP");
    },
    get ORGANIZATION() {
      return stubEntities("ORGANIZATION");
    },
    get SALES_PROCESS_OVERRIDE() {
      return stubEntities("SALES_PROCESS_OVERRIDE");
    },
    get STEP() {
      return stubEntities("STEP");
    },
    get TAG() {
      return stubEntities("TAG");
    },
  };
};
