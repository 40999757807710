// @ts-check

import { Modal } from "antd";
import { useFirstMountState } from "react-use";
import React, { useRef, useState } from "react";

import * as domain from "@evolved/domain";
import { getPartnersLabel } from "@evolved/labels";

import getErrorMessage from "../../../utils/get-error-message";
import { useOrganization } from "../../../data/use-organization";

import { ConfigureEntityMatch } from "./configure-matches/configure-entity-match";
import { ConfigureRelationshipMatches } from "./configure-matches/configure-relationship-matches";
import { ConfigureUdfs } from "./configure-udfs";
import { Confirm } from "./confirm";
import { ErrorMessage } from "../../../components/error-message";
import { FileUpload } from "./file-upload";
import { GetTemplate } from "./get-template";
import { IdentifyHeaders } from "./identify-headers";
import { buildImportState } from "./import-state/import-state";
import { filterSelectedUdfs } from "./filter-selected-new-udfs";
import { getImportFields } from "./get-import-fields";
import { useEntityCache } from "./use-entity-cache";

/** @typedef {import("./domain").ImportableEntityTypes} Types */

/**
 * @param {Object} o
 * @param {domain.Organization} o.organization
 * @param {domain.EntityType} o.entityType
 *
 * @returns {string}
 */
const getTitle = ({ organization, entityType }) => {
  /** @type {Record<Types, string>} */
  const options = {
    ACCOUNT: "Account Import",
    ACTIVITY: "Activity Import",
    CONTACT: "Contact Import",
    OPPORTUNITY: "Opportunity Import",
    VENDOR: `${getPartnersLabel(organization, "singular")} Import`,
  };

  return options[entityType];
};

/** @typedef {import("./domain").ImportField} ImportField
/** @typedef {import("./domain").ImportState} ImportState

/**
 * @typedef {Object} WizardProps
 *
 * @prop {Types} entityType
 * @prop {import("./use-entity-cache").EntityCache} entityCache
 * @prop {domain.Organization} organization
 * @prop {ImportField[]} importFields
 *
 * @prop {import("@evolved/domain").Organization} organization
 * @prop {() => void} onSuccess
 */

/**
 * Import wizard component that guides users through the data import process
 * @param {WizardProps} props
 */
const Wizard = (props) => {
  const { entityType, entityCache, importFields, organization, onSuccess } = props;

  const isFirst = useFirstMountState();

  /** @type {domain.UseState<{value?: string}>} */
  const [error, setError] = useState({});

  // TODO: wrap this entire thing in a boundary
  // and display errors under form if any are caught.
  //
  // Have to restart since in a bad state, maybe.
  /**
   * @param {Error} error
   */
  const onError = (error) => {
    console.error(error);
    setError({ value: error.message });
  };

  const importState = useRef(isFirst && buildImportState({
    entityType,
    entityCache,
    importFields,
    userDefinedFields: organization.userDefinedFields,
  })).current;

  /** @type {{
   *  importState: import("./domain").ImportState;
   *  currentStep: import("./domain").ImportStep;
   * }} */
  const initialState = {
    importState: importState.getState(),
    currentStep: "upload_file",
  };
  const [state, setState] = useState(initialState);

  /**
   * @param {{
   *  importState?: import("./domain").ImportState;
   *  currentStep?: import("./domain").ImportStep;
   * }} update
   */
  const updateState = (update) => {
    setState((state) => {
      return {
        ...state,
        ...update,
      }
    });

    setError({});
  }

  importState.setOnUpdate((importState) => {
    updateState({ importState });
  });
  importState.setOnError(onError);

  const getCurrentStepIndex = () => {
    const index = state.importState.validStepIds.findIndex((step) => {
      return step === state.currentStep;
    });

    if (index < 0) {
      throw new Error("Should always find current step.");
    }

    return index;
  }

  const getPreviousStepId = () => {
    const index = getCurrentStepIndex();

    if (index === 0) {
      return state.importState.validStepIds[0];
    }

    return state.importState.validStepIds[index - 1];
  };

  /**
   * @returns {import("./domain").ImportStep | null}
   */
  const getNextStepId = () => {
    const index = getCurrentStepIndex();

    if (index === state.importState.validStepIds.length - 1) {
      return null;
    }

    return state.importState.validStepIds[index + 1];
  };

  const onBack = () => {
    updateState({ currentStep: getPreviousStepId() });
  };

  const nextId = getNextStepId();

  /**
   * @type {null | ((first?: (() => void)) => void)}
   */
  const onNext = nextId && ((first) => {
    try {
      if (typeof first === "function") {
        first();
      }

      updateState({ currentStep: nextId });
    } catch (e) {
      onError(e);
    }
  });

  /** @type {JSX.Element | null} */
  let renderedStep = null;

  if (state.currentStep === "upload_file") {
    renderedStep = (
      <div>
        {state.importState.headers.duplicateHeaders.length ? (
          <>
            <div className="mb-2">Duplicate headers found:</div>
            <div className="mb-2 font-semibold">
              {state.importState.headers.duplicateHeaders.join(", ")}
            </div>
            <div className="mb-2">
              Please reconcile them and submit again.
            </div>
          </>
        ) : (
          <GetTemplate {...{ entityType, importFields, onError }} />
        )}
        <FileUpload
          onSuccess={(upload) => {
            const next = importState.setUpload(upload)
            if (next?.validStepIds.includes("configure_header_data_indexes")) {
              updateState({
                currentStep: "configure_header_data_indexes",
              });
            }
          }}
          onNext={onNext}
          onError={onError}
        />
      </div>
    );
  }

  if (state.currentStep === "configure_header_data_indexes") {
    renderedStep = (
      <IdentifyHeaders
        {...{
          service: importState,
          state: state.importState,
          onBack,
          onNext: onNext ? () => {
            onNext(() => {
              importState.syncEntityMatchConfigDataIndexes();
            });
          } : null,
        }}
      />
    );
  }

  if (state.currentStep === "create_user_defined_fields") {

    renderedStep = (
      <ConfigureUdfs
        {...{
          service: importState,
          state: state.importState,
          onBack,
          onNext,
        }}
      />
    );
  }

  if (state.currentStep === "configure_entity_match") {
    renderedStep = (
      <ConfigureEntityMatch
        {...{
          onBack,
          onNext,
          service: importState,
          state: state.importState,
        }}
      />
    );
  }

  if (state.currentStep === "configure_relationships_matches") {
    renderedStep = (
      <ConfigureRelationshipMatches
        {...{
          importFields,
          onBack,
          onNext,
          service: importState,
          state: state.importState,
        }}
      />
    );
  }

  if (state.currentStep === "confirm") {
    renderedStep = (
      <Confirm
        {...{
          entityType,
          existingUdfs: organization.userDefinedFields,
          reducedRows: state.importState.reducedRows,
          newUdfs: filterSelectedUdfs(state.importState.newUserDefinedFields),
          onBack,
          onSuccess,
        }}
      />
    )
  }

  return (
    <div className="w-full">
      <div className="p-2">
        {renderedStep}
      </div>
      {error.value && (
        <ErrorMessage
          action={null}
          refresh={null}
          error={`${getErrorMessage(
            error.value
          )}. Take any corrective actions and re-upload the file to try again.`}
          style={{ marginTop: "24px" }}
          support={false}
        />
      )}
    </div>
  );
};

/**
 * @param {{
 *  isOpen: boolean;
 *  type: Types;
 *  close: () => void;
 * }} props
 */
export const ImportDataModal = (props) => {
  const { type: entityType, close } = props;

  /** @type {domain.Organization} */
  const organization = useOrganization();

  const entityCache = useEntityCache({
    useActivities: entityType === "ACTIVITY",
  });
  const importFields = getImportFields({
    entityType,
    userDefinedFields: organization.userDefinedFields,
  });

  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={props.close}
      open={props.isOpen}
      title={getTitle({ entityType, organization })}
    >
      <div className="flex w-[100%] justify-center">
        <Wizard {...{ entityType, entityCache, organization, importFields, onSuccess: close }} />
      </div>
    </Modal>
  );
};
