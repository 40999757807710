// @ts-check

/** @typedef {import("@evolved/domain").SystemField} SystemField */

/** @type {SystemField} */
export const name = {
  entityType: "OPPORTUNITY_STATE",
  type: "TEXT",
  title: "Name",
  canEdit: true,
  isSystem: true,
  dataIndex: "label",
  isRequired: true,
  canMatchEntityBy: true,
};

export const COLLECTION = Object.freeze([name]);
