// @ts-check

import React from "react";
import { Button } from "antd";

import { RowInfo } from "./row-info";
import { useImportAccountData } from "../../../data/use-accounts";
import { useImportContactData } from "../../../data/use-contacts";
import { useImportOpportunityData } from "../../../data/use-opportunities";
import { useImportVendorData } from "../../../data/use-vendors";
import { useImportActivityData } from "../../../data/use-activities";

/** @typedef {import("@evolved/domain").UserDefinedField[]} UDFs */

/**
 * @param {Object} o
 * @param {UDFs} o.existingUdfs
 * @param {UDFs} o.newUdfs
 */
const prepareUdfPayload = ({ existingUdfs, newUdfs }) => {
  const prepared = [...existingUdfs];

  for (const udf of newUdfs) {
    if (prepared.find(({ id }) => udf.id === id)) {
      continue;
    }

    prepared.push(udf);
  }

  return prepared;
};

/**
 * @param {Object} props 
 * @param {import("./domain").ImportableEntityTypes} props.entityType
 * @param {import("./domain").ReducedRow[]} props.reducedRows
 * @param {UDFs} props.existingUdfs
 * @param {UDFs} props.newUdfs
 * @param {() => void} props.onBack
 * @param {() => void} props.onSuccess
 */
export const Confirm = ({
  entityType,
  newUdfs,
  existingUdfs,
  reducedRows,
  onBack,
  onSuccess,
}) => {
  const importData = ({
    ACCOUNT: useImportAccountData({
      onSuccess,
    }),
    ACTIVITY: useImportActivityData({
      onSuccess,
    }),
    CONTACT: useImportContactData({
      onSuccess,
    }),
    OPPORTUNITY: useImportOpportunityData({
      onSuccess,
    }),
    VENDOR: useImportVendorData({
      onSuccess,
    }),
  })[entityType];

  const onConfirm = () => {
    importData.mutate(
      {
        entities: reducedRows.filter(({ isValid }) => {
          return isValid;
        }).map(({ entity }) => entity),
        userDefinedFields: prepareUdfPayload({
          newUdfs,
          existingUdfs,
        }),
      });
  }

  const updateCount = reducedRows.filter(({ isValid, previous }) => isValid && !!previous).length;
  const newCount = reducedRows.filter(({ isValid, previous }) => isValid && !previous).length;
  const warningCount = reducedRows.filter(({ info, isValid }) => isValid && info.some(({ level }) => level === "warning")).length;
  const issueCount = reducedRows.filter(({ isValid }) => !isValid).length;

  return (
    <div>
      <div className="mb-2">
        You are about to update {updateCount} and create {newCount} records.
      </div>
      <div className="mb-2">
        There are {warningCount} rows with warnings that will be imported and {issueCount} rows with errors that will not be imported.
      </div>
      <div className="mb-2">
        Would you like to proceed?
      </div>
      <div>
        {reducedRows.map((row, index) => {
          return (
            <RowInfo
              key={index}
              {...{
                info: row.info,
                index,
                filter: (info) => {
                  if (info.level === "info") {
                    return false;
                  }

                  if (info.type === "entity_match_info" || info.type === "relationship_match_info") {
                    return false;
                  }

                  return true;
                },
              }}
            />
          )
        })}
      </div>

      <div className="flex justify-end">
        <Button
          className="mr-2"
          disabled={importData.isLoading}
          onClick={onBack}
        >Back</Button>
        <Button
          type="primary"
          onClick={onConfirm}
          loading={importData.isLoading}
          disabled={importData.isLoading || (!newCount && !updateCount)}
        >
          Confirm Import
        </Button>
      </div>
    </div >
  )
};
