// @ts-check

/**
 * @param {Object} o
 * @param {string} o.dataIndex
 * @param {import("../domain").ReducedRow[]} o.reducedRows
 */
export const reduceRelationshipMatchReport = ({ dataIndex, reducedRows }) => {
  // NOTE: each row has multiple things to match and it may
  // match one or many or none
  // - the index may show up multiple times

  /**
   * @param {{
   *  cardinalityOverflow: number[];
   *  willOverwriteWithNothing: number[];
   *  unmatched: Record<string, {rowIndexes: number[];}>;
   *  matched: {
   *    valid: Record<string, {rowIndexes: number[]; entityId: string;} >;
   *    duplicate: Record<string, {rowIndexes: number[]; entityIds: string[];}>;
   *  };
   * }} acc
   * @param {import("../domain").ReducedRow} reducedRow
   * @param {number} index
   */
  const reduceResult = (acc, reducedRow, index) => {
    for (const info of reducedRow.info) {
      if (info.type !== "relationship_match_info") {
        continue;
      }

      if (info.dataIndex !== dataIndex) {
        continue;
      }

      if ("willOverwriteWithNothing" in info) {
        acc.willOverwriteWithNothing = [
          ...new Set([...acc.willOverwriteWithNothing, index]),
        ];
        continue;
      }

      if ("cardinalityOverflow" in info) {
        acc.cardinalityOverflow = [
          ...new Set([...acc.cardinalityOverflow, index]),
        ];
        continue;
      }

      if ("matches" in info) {
        for (const match of info.matches) {
          acc.matched.valid[match.matchKey] = {
            rowIndexes: [
              ...(acc.matched.valid[match.matchKey]?.rowIndexes ?? []),
              index,
            ],
            entityId: match.entityId,
          };
        }
      }

      if ("unmatched" in info) {
        for (const unmatched of info.unmatched) {
          acc.unmatched[unmatched.matchKey] = {
            rowIndexes: [
              ...(acc.unmatched[unmatched.matchKey]?.rowIndexes ?? []),
              index,
            ],
          };
        }
      }

      if ("duplicates" in info) {
        for (const match of info.duplicates) {
          acc.matched.duplicate[match.matchKey] = {
            rowIndexes: [
              ...(acc.matched.duplicate[match.matchKey]?.rowIndexes ?? []),
              index,
            ],
            entityIds: [
              ...new Set([
                ...(acc.matched.duplicate[match.matchKey]?.entityIds ?? []),
                ...match.entityIds,
              ]),
            ],
          };
        }
      }
    }

    return acc;
  };

  return reducedRows.reduce(reduceResult, {
    cardinalityOverflow: [],
    willOverwriteWithNothing: [],
    unmatched: {},
    matched: {
      valid: {},
      duplicate: {},
    },
  });
};

/**
 * @typedef {ReturnType<typeof reduceRelationshipMatchReport>} RelationshipMatchReport
 */
