// @ts-check

import set from "lodash/set";

/**
 * @param {Object} o
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {string[]} o.row
 */
export const zipRow = ({ headerDataIndexes, row }) => {
  return row.reduce((acc, value, index) => {
    const key = headerDataIndexes[index];

    if (!key) {
      return acc;
    }

    set(acc, key, value);
    return acc;
  }, {});
};
