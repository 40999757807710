// @ts-check

import get from "lodash/get";

import { normalize } from "../are-headers-equal";

/**
 * @param {Object} o
 * @param {string} o.value
 * @param {string} [o.split]
 */
export const splitMultiMatchKeys = ({ value, split = "," }) => {
  return value
    .split(split)
    .map(normalize)
    .filter((v) => !!v);
};

/**
 * @param {Object} o
 * @param {object} o.entity
 * @param {string} o.dataIndex
 * @param {string} [o.split]
 */
export const getRelationshipMatchKeys = ({
  entity,
  dataIndex,
  split = ",",
}) => {
  /** @type {string} */
  const value = get(entity, dataIndex) ?? "";

  return splitMultiMatchKeys({
    value,
    split,
  });
};
