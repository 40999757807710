// @ts-check

import React, { useState } from "react";
import { Alert, Button, Checkbox, Select } from "antd";
import { isArray } from "radash";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

/**
 * @typedef {Object} Props
 *
 * @prop {import("../domain").ImportField} relationshipField
 * @prop {import("../domain").RelationshipMatchConfig} relationshipMatchConfig
 * @prop {import("../import-state/import-state").ImportStateService} service
 * @prop {import("./reduce-relationship-match-report").RelationshipMatchReport} relationshipMatchReport
 * @prop {{label: string; value: string;}[]} dataIndexOptions
 */

// TODO: Probably need to support not updating anything? 
// For example, what if there are no records in the system 
// to update? Then it is just an update.

/**
 * @param {Props} props
 */
export const ConfigureRelationshipMatch = (props) => {
  const {
    dataIndexOptions,
    relationshipField,
    relationshipMatchConfig,
    relationshipMatchReport,
    service,
  } = props;

  const [isMulti, setIsMulti] = useState(relationshipMatchConfig.dataIndexes.map((dataIndexes) => dataIndexes.length > 1));

  if (relationshipField.type !== "SET") {
    throw new Error("relationshipField should be SET");
  }

  /**
   * @type {JSX.Element[]}
   */
  const successes = [];

  const validMatched = Object.entries(relationshipMatchReport.matched.valid);

  if (validMatched.length) {
    const total = validMatched.flatMap(([, { rowIndexes }]) => rowIndexes).length;

    successes.push(
      <React.Fragment key="update_success">
        connect <strong>{total}</strong> matched relationships
      </React.Fragment>
    );
  }

  /**
   * @type {JSX.Element[]}
   */
  const issues = [];

  if (relationshipMatchReport.willOverwriteWithNothing.length) {
    issues.push(
      <>
        <div className="mb-2">
          No matches on rows with existing {relationshipField.name} data: {relationshipMatchReport.willOverwriteWithNothing.map(index => index + 1).join(", ")}
        </div>
        <div className="mb-2">
          These would be overwritten with nothing, skipping to prevent accidental deletion.</div>
        <div className="mb-2">
          If you intended to do this, re-upload the import with an empty value.
        </div>
      </>
    )
  }

  const hasSelections = !!relationshipMatchConfig.dataIndexes.flat().length;
  if (!hasSelections) {
    issues.push(
      <div className="mb-2">
        Must make a selection to match the relationship field.
      </div>
    );
  }

  const unmatched = Object.entries(relationshipMatchReport.unmatched);
  if (hasSelections && unmatched.length) {
    issues.push(
      <>
        <div className="mb-2">
          Cannot find matches for match keys on rows:
        </div>
        {unmatched.map(([matchKey, { rowIndexes }]) => {
          return (
            <div className="mb-2" key={matchKey}>
              <strong>{matchKey}</strong>: {rowIndexes.map(index => index + 1).join(", ")}
            </div>
          );
        })}
      </>
    );
  }

  if (hasSelections && !successes.length) {
    issues.push(
      <div>No matches were found. This import will not relate any {relationshipField.name} records.</div>
    );
  }

  // NOTE: there are duplicates in CRM that were matched to,
  // but we can't do anything with them.
  //
  // Reconcile the records in the system to continue.

  const matchedDuplicates = Object.entries(relationshipMatchReport.matched.duplicate);

  if (hasSelections && matchedDuplicates.length) {
    issues.push(
      <>
        <div className="mb-1">Relationships matched to multiple records in CRM:</div>
        {matchedDuplicates.map(([matchKey, { rowIndexes, entityIds }]) => {
          return (
            <div key={matchKey}>
              <strong>{matchKey}</strong> exists on {entityIds.length} CRM record{entityIds.length ? "s" : ""} matching to import rows:{" "}
              {rowIndexes.map((index) => index + 1).join(", ")}
            </div>
          );
        })}
        <div className="mb-1">Matches must be unique to relate.</div>
      </>
    );
  }

  return (
    <div className="w-full">
      {relationshipMatchConfig.dataIndexes.map((dataIndexes, index) => {
        return (
          <React.Fragment key={index}>
            <div className="mb-2 flex items-center">
              <Checkbox
                checked={isMulti[index]}
                onChange={() => {
                  if (isMulti[index] && dataIndexes.length > 1) {
                    service.setRelationshipMatchKey({
                      dataIndex: relationshipField.dataIndex,
                      index,
                      value: [dataIndexes[0]],
                    });
                  }

                  const next = [...isMulti];
                  next.splice(index, 1, !isMulti[index]);

                  setIsMulti(next);
                }}
              />
              <div className={"mx-2 w-[112px]"}>combination of</div>
              <Select
                className="flex-1"
                mode={isMulti[index] ? "multiple" : undefined}
                value={dataIndexes}
                options={dataIndexOptions}
                onChange={(value) => {
                  service.setRelationshipMatchKey({
                    dataIndex: relationshipField.dataIndex,
                    index,
                    value: isArray(value) ? value : [value],
                  });
                }}
              />
              {service.canRemoveRelationshipMatchKey()(relationshipField.dataIndex) ? <Button
                rootClassName="ml-2"
                shape="circle"
                size="small"
                icon={<MinusOutlined />}
                onClick={() => {
                  const nextIsMulti = [...isMulti];
                  nextIsMulti.splice(index, 1);
                  setIsMulti(nextIsMulti);

                  service.removeRelationshipMatchKey({
                    index,
                    dataIndex: relationshipField.dataIndex,
                  });
                }}
              /> : null}
            </div>
          </React.Fragment>
        )
      })}
      <div className="flex justify-start mb-2">
        <Button
          disabled={!service.canAddRelationshipMatchKey()(relationshipField.dataIndex)}
          onClick={() => service.addRelationshipMatchKey(relationshipField.dataIndex)}
          type="primary"
          icon={<PlusOutlined />}
          size="small"
        >Add</Button>
      </div>

      {issues.length
        ? issues.map((issue, index) => {
          return (
            <Alert
              message={issue}
              type="warning"
              className="!mb-3"
              key={index}
            />
          );
        })
        : null}

      {successes.length ? (
        <Alert
          type="success"
          message={<div>Import will {successes} records.</div>}
          className="!mb-2"
        />
      ) : null}
    </div>
  );
};
