// @ts-check

import React from "react";
import { Alert } from "antd";

/**
 * @param {Object} props 
 * @param {number} props.index 0 based index of import row
 * @param {import("./domain").ImportRowInfo[]} props.info
 * @param {((info: import("./domain").ImportRowInfo) => boolean)} [props.filter]
 */
export const RowInfo = ({ index, info, filter }) => {
  /** @type {import("./domain").ImportRowInfo[]} */
  const errors = [];
  /** @type {import("./domain").ImportRowInfo[]} */
  const warnings = [];

  // TODO: don't support yet... will just not show... yet...
  /** @type {import("./domain").ImportRowInfo[]} */
  const _info = [];

  for (const i of info) {
    if (filter && !filter(i)) {
      continue;
    }

    if (i.level === "error") {
      errors.push(i);
    }

    if (i.level === "warning") {
      warnings.push(i);
    }
  }

  if (!errors.length && !warnings.length && !_info.length) {
    return null;
  }

  return (
    <div>
      <div>Row {index + 1}</div>
      {errors.map(i => {
        if (i.level !== "error") {
          return null;
        }

        /** @type {React.JSX.Element | null} */
        let message = null;

        if (i.type === "entity_match_info") {
          for (const matchKey of i.matchKeys) {
            if (!("duplicates" in matchKey)) {
              continue;
            }

            message = (
              <div>
                <div className="text-lg">Entity Match Error</div>
                {matchKey.duplicates.rowIndexes.length ? (
                  <div>
                    <strong>{matchKey.matchKey}</strong> parsed on rows {matchKey.duplicates.rowIndexes.map((index) => index + 1)}. Must be unique to match.
                  </div>
                ) : null}
                {matchKey.duplicates.entityIds.length ? (
                  <div>
                    <strong>{matchKey.matchKey}</strong> matched {matchKey.duplicates.entityIds.map((index) => index + 1)}. Must match exactly one to update.
                  </div>
                ) : null}
              </div>
            )
          }
        } else if (i.type === "entity_validation_failed") {
          message = (
            <div>
              <div className="text-lg">Entity validation Error</div>
              {i.errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )
        }

        if (!message) {
          return null;
        }

        return (
          <Alert
            message={message}
            type="error"
          />
        );
      })}

      {warnings.map(i => {
        if (i.level !== "warning") {
          return null;
        }

        /** @type {React.JSX.Element | null} */
        let message = null;

        if (i.type === "relationship_match_info") {
          if ("cardinalityOverflow" in i) {
            message = (
              <div>
                <div className="text-lg">Relationship Match Warning</div>
                <div>This field only supports one entity, this row contains multiple.</div>
              </div>
            );
          } else {
            message = (
              <div>
                <div className="text-lg">Relationship Match Warning</div>
                {i.unmatched.map(({ matchKey }, index) => {
                  return (
                    <div key={index}>No matches found for <strong>{matchKey}</strong></div>
                  )
                })}
                {i.duplicates.map(({ matchKey, entityIds }, index) => {
                  return (
                    <div key={index}><strong>{matchKey}</strong> matched {entityIds.length} entities. Must match exactly one to update.</div>
                  )
                })}
              </div>
            );
          }
        } else if (i.type === "value_conversion_error") {
          message = (
            <div>
              <div>Could not convert value at column {i.headerIndex + 1}: {i.error}</div>
            </div>
          );
        }

        if (!message) {
          return null;
        }

        return (
          <Alert
            message={message}
            type="warning"
          />
        );
      })}
    </div>
  )

};
