// @ts-check

import { normalize } from "./are-headers-equal";
import { findField } from "./find-field";

/**
 * @param {Object} o
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {(string | null)[]} o.headerDataIndexes
 */
export const getSelectFieldOptionsCache = ({
  headerDataIndexes,
  importFields,
}) => {
  /**
   * @type {(Record<string, string> | null)[]}
   *
   * If headerIndex is a select field, then
   * have a record of normalized option label
   * to id.
   *
   */
  return headerDataIndexes.map((dataIndex) => {
    if (!dataIndex) {
      return null;
    }

    const field = findField(dataIndex)(importFields);

    if (field.type !== "SELECT") {
      return null;
    }

    /**
     * @type Record<string, string>
     **/
    const result = {};

    for (const option of field.options) {
      result[normalize(option.label)] = option.id;
    }

    return result;
  });
};
