// @ts-check

/**
 * @param {Object} o
 * @param {object} o.entity
 * @param {object} [o.previous]
 */

export const mergeEntityWithPrevious = ({ entity, previous = {} }) => {
  /** @type {object} */
  let userDefinedFields;

  if (previous.userDefinedFields || entity.userDefinedFields) {
    userDefinedFields = {
      ...previous.userDefinedFields,
      ...entity.userDefinedFields,
    };
  }

  return {
    ...previous,
    ...entity,
    ...(userDefinedFields ? { userDefinedFields } : {}),
  };
};
