// @ts-check

import get from "lodash/get";

import { normalize } from "../are-headers-equal";

/**
 * @param {Object} o
 * @param {object} o.entity
 * @param {string[]} o.dataIndexes
 *
 * @returns {string}
 */

export const getEntityMatchKey = ({ entity, dataIndexes }) => {
  return dataIndexes
    .map((dataIndex) => get(entity, dataIndex))
    .filter((value) => !!value)
    .map(normalize)
    .join(" ");
};

/**
 * @param {Object} o
 * @param {object} o.entity
 * @param {string[][]} o.dataIndexes
 */

export const getEntityMatchKeys = ({ entity, dataIndexes }) => {
  return dataIndexes
    .filter((dataIndexes) => dataIndexes.length)
    .map((dataIndexes) => {
      return getEntityMatchKey({ entity, dataIndexes });
    });
};

/**
 * @param {Object} o
 * @param {object[]} o.entities
 * @param {string[][]} o.dataIndexes - dataIndex to match on
 *
 * @returns {Record<string, string[]>}
 */
export const reduceEntitiesByMatchKeys = ({ entities, dataIndexes }) => {
  /**
   * @param {Record<string, string[]>}  acc
   * @param {object} entity
   *
   * @returns {Record<string, string[]>}
   */
  const reducer = (acc, entity) => {
    const keys = getEntityMatchKeys({ entity, dataIndexes });

    for (const key of keys) {
      if (!key) {
        continue;
      }

      const id = entity._id;
      if (id === null || id === undefined) {
        throw new Error(
          `Entity ${JSON.stringify(
            entity
          )} matched on '${key}' does not have _id. Something has gone very wrong.`
        );
      }

      if ((acc[key] ?? []).includes(id)) {
        continue;
      }

      acc[key] = [...(acc[key] ?? []), id];
    }

    return acc;
  };

  return entities.reduce(reducer, {});
};
