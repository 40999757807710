// @ts-check

import { normalizeHeader } from "./normalize-header";

/**
 * Finds a unique list of duplicate headers
 * based on our normalization rules.
 *
 * @param {string[]} headers
 * @returns string[]
 */
export const getDuplicateHeaders = (headers) => {
  const seen = [];
  const duplicates = [];

  for (const header of headers) {
    const s = seen.find((h) => normalizeHeader(h) === normalizeHeader(header));

    if (
      s &&
      !duplicates.find((h) => normalizeHeader(h) === normalizeHeader(header))
    ) {
      duplicates.push(s);
      continue;
    }

    seen.push(header);
  }

  return duplicates;
};
