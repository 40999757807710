// @ts-check

/**
 * @type {Record<import("@evolved/domain").EntityType, string[][]>}
 */
const defaultMatchDataIndexes = {
  ACCOUNT: [["_id"], ["name"]],
  ACTIVITY: [["_id"]],
  ACTIVITY_TYPE: [["_id"], ["label"]],
  CONTACT: [["_id"], ["email"], ["firstName", "lastName"]],
  LOSS_REASON: [["_id"], ["label"]],
  OPPORTUNITY: [["_id"], ["alias"]],
  OPPORTUNITY_STATE: [["_id"], ["label"]],
  PRODUCT: [["_id"], ["name"]],
  SALES_PROCESS: [["_id"], ["name"]],
  TAG: [["_id"], ["label"]],
  USER: [["_id"], ["email"], ["firstName", "lastName"]],
  VENDOR: [["_id"], ["name"]],

  // NOTE: cannot match yet
  STEP: [],
  FOLLOWUP: [],
  // NOTE: would never match this
  ORGANIZATION: [],
  ACTIVITY_STEP_IMPACT: [],
  SALES_PROCESS_OVERRIDE: [],
};

/**
 * @param {Object} o
 * @param {import("@evolved/domain").EntityType} o.entityType
 * @param {(string | null)[]} [o.headerDataIndexes]
 *
 * Get defaultDataIndexes. The priorityMatchField is prioritized.
 */
export const getDefaultDataIndexes = ({ entityType, headerDataIndexes }) => {
  /** @type {string[][]} */
  const _defaultMatchDataIndexes = [];

  for (const defaults of defaultMatchDataIndexes[entityType]) {
    const _defaults = defaults.filter(
      (dataIndex) => !headerDataIndexes || headerDataIndexes.includes(dataIndex)
    );

    if (_defaults.length) {
      _defaultMatchDataIndexes.push(_defaults);
    }
  }

  return _defaultMatchDataIndexes;
};
