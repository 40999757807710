// @ts-check

import React, { useState } from "react";
import isArray from "lodash/isArray";
import { Alert, Button, Checkbox, Select } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

/**
 * @typedef {Object} Props
 *
 * @prop {import("../import-state/import-state").ImportStateService} service
 * @prop {import("../domain").ImportState} state
 *
 * @prop {() => void} onBack
 * @prop {(() => void) | null} onNext
 */

/**
 * @param {Props} props
 */
export const ConfigureEntityMatch = (props) => {
  const { onNext, onBack, service, state } =
    props;

  const {
    config,
    dataIndexOptions,
    requiredFields,
    canCreateIfNoMatch,
    report,
  } = state.entityMatch;

  const [isMulti, setIsMulti] = useState(config.dataIndexes.map((dataIndexes) => dataIndexes.length > 1));

  /**
   * @type {JSX.Element[]}
   */
  const successes = [];

  if (canCreateIfNoMatch && config.createIfNoMatch && (report.unmatched.length || report.empty.length)) {
    successes.push(
      <React.Fragment key="create_success">
        create <strong>{report.unmatched.length + report.empty.length}</strong> new
      </React.Fragment>
    );
  }

  if (report.matched.valid.length) {
    successes.push(
      <React.Fragment key="update_success">
        update <strong>{report.matched.valid.length}</strong> existing
      </React.Fragment>
    );
  }

  if (successes.length === 2) {
    successes.splice(1, 0, <> and </>);
  }

  /**
   * @type {JSX.Element[]}
   */
  const issues = [];

  if (report.unmatched.length && !canCreateIfNoMatch) {
    issues.push(
      <div className="mb-2">
        Cannot create entities for <strong>{report.unmatched.length}</strong>{" "}
        unmatched rows as field{requiredFields.length > 2 ? "s" : ""}:
        <br />
        <strong>{requiredFields.map(({ name }) => name).join()}</strong>
        <br />
        {requiredFields.length > 2 ? "are" : "is"} required.
      </div>
    );
  }

  // NOTE: there are duplicates in CRM that were matched to,
  // but we can't do anything with them.
  //
  // Reconcile the records in the system to continue.
  if (report.matched.duplicate.length) {
    issues.push(
      <>
        <div className="mb-1">Entities matched to multiple records in CRM:</div>
        {report.matched.duplicate.map(([key, rowIndexes]) => {
          return (
            <div>
              <strong>{key}</strong> exists on multiple CRM records and matched to import rows:{" "}
              {rowIndexes.map((index) => index + 1).join(", ")}
            </div>
          );
        })}
        <div className="mb-1">matches must be unique to update.</div>
      </>
    );
  }

  // NOTE: we want to know how many duplicate incoming rows there are,
  // we cannot match them to anything, cannot create them, they will be ignored.
  if (report.duplicates.length) {
    issues.push(
      <>
        <div className="mb-2">
          Duplicate match values were found in your import:
        </div>
        {report.duplicates.map(([key, rowIndexes]) => {
          return (
            <div key={key} className="px-4">
              <strong>{key}</strong> on rows:{" "}
              {rowIndexes.map((index) => index + 1).join(", ")}
            </div>
          );
        })}
        <div className="my-2">
          Make these unique to create or update these rows.
        </div>
      </>
    );
  }

  return (
    <div className="w-full">
      <>
        <div className="mb-2">
          Which field(s) match your import rows to records in CRM?
        </div>
        <div className="mb-2">
          <strong>Matches will be updated. Unmatched will be created.</strong>
        </div>
        {config.dataIndexes.map((dataIndexes, index) => {
          return (
            <React.Fragment key={index}>
              <div className="mb-2 flex items-center">
                <Checkbox
                  checked={isMulti[index]}
                  onChange={() => {
                    if (isMulti[index] && dataIndexes.length > 1) {
                      service.setEntityMatchKey({
                        index,
                        value: [dataIndexes[0]],
                      });
                    }

                    const next = [...isMulti];
                    next.splice(index, 1, !isMulti[index]);

                    setIsMulti(next);
                  }}
                />
                <div className={"mx-2 w-[112px]"}>combination of</div>
                <Select
                  allowClear
                  className="flex-1"
                  mode={isMulti[index] ? "multiple" : undefined}
                  onChange={(value) => {
                    service.setEntityMatchKey({
                      value: isArray(value) ? value : [value],
                      index
                    });
                  }}
                  options={dataIndexOptions}
                  value={dataIndexes}
                />
                {service.canRemoveEntityMatchKey() ?
                  <Button
                    rootClassName="ml-2"
                    shape="circle"
                    size="small"
                    icon={<MinusOutlined />}
                    disabled={!service.canRemoveEntityMatchKey()}
                    onClick={() => {
                      const nextIsMulti = [...isMulti];
                      nextIsMulti.splice(index, 1);
                      setIsMulti(nextIsMulti);

                      service.removeEntityMatchKey({
                        index,
                      });
                    }}
                  />
                  : null}

              </div>
            </React.Fragment>
          )
        })}
      </>
      <div className="flex justify-start mb-2 items-center">
        <Button
          disabled={!service.canAddEntityMatchKey()}
          onClick={() => service.addEntityMatchKey()}
          type="primary"
          icon={<PlusOutlined />}
          size="small"
        >Add</Button>
      </div>


      {canCreateIfNoMatch && report.unmatched.length ? (
        <div className="mb-2 flex items-start">
          <Checkbox
            onChange={(e) => {
              service.setCreateEntityIfNoMatch({
                value: e.target.checked
              });
            }}
            checked={config.createIfNoMatch}
            style={{ marginRight: "8px" }}
          />
          {report.unmatched.length > 1 ? (
            <div>
              Create new records for the{" "}
              <strong>{report.unmatched.length}</strong> rows that do not match?
            </div>
          ) : (

            <div>
              Create new record for the{" "}
              <strong>{report.unmatched.length}</strong> row that does not match?
            </div>
          )}
        </div>
      ) : null}

      {issues.length
        ? issues.map((issue, index) => {
          return (
            <Alert
              message={issue}
              type="warning"
              className="!mb-3"
              key={index}
            />
          );
        })
        : null}

      {successes.length ? (
        <Alert
          type="success"
          message={<div>Import will {successes} records.</div>}
          className="!mb-2"
        />
      ) : (
        <Alert
          type="warning"
          message={
            <div>This import will not update or create any new records.</div>
          }
          className="!mb-2"
        />
      )}

      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>Back</Button>
        <Button type="primary" disabled={!onNext} onClick={onNext ?? undefined}
        >Next</Button>
      </div>
    </div>
  );
};
