// @ts-check

import { reduceRowsByMatchKeys } from "./get-row-matches";

/**
 * @param {Object} o
 * @param {Record<string, string[]>} o.entitiesByMatchKeys
 * @param {Record<string, number[]>} o.rowsByMatchKeys
 * @param {string[][]} o.rows
 */
export const reduceEntityMatchReport = ({
  entitiesByMatchKeys,
  rowsByMatchKeys,
  rows,
}) => {
  /**
   * @param {{
   *  duplicates: [string, number[]][];
   *  unmatched: [string, number][];
   *  empty: number[];
   *  matched: {
   *    valid: [string, number][];
   *    duplicate: [string, number[]][];
   *  };
   * }} acc
   * @param {[string, number[]]} rowsByMatchKey
   */
  const reduceResult = (acc, [key, rowIndexes]) => {
    if (!key) {
      acc.empty.push(...rowIndexes);
      return acc;
    }

    if (rowIndexes.length > 1) {
      acc.duplicates.push([key, rowIndexes]);
      return acc;
    }

    const match = entitiesByMatchKeys[key];
    if (!match) {
      acc.unmatched.push([key, rowIndexes[0]]);
      return acc;
    }

    if (match.length > 1) {
      acc.matched.duplicate.push([key, rowIndexes]);
      return acc;
    }

    acc.matched.valid.push([key, rowIndexes[0]]);
    return acc;
  };

  if (!Object.entries(rowsByMatchKeys).length) {
    return {
      duplicates: [],
      unmatched: [],
      empty: rows.map((_row, index) => index),
      matched: {
        valid: [],
        duplicate: [],
      },
    };
  }

  return Object.entries(rowsByMatchKeys).reduce(reduceResult, {
    duplicates: [],
    unmatched: [],
    empty: [],
    matched: {
      valid: [],
      duplicate: [],
    },
  });
};

/**
 * @typedef {ReturnType<typeof reduceEntityMatchReport>} EntityMatchReport
 */
