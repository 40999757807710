import { getRequiredFields } from "./get-required-fields";

/**
 * @param {Object} o
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {(string | null)[]} o.headerDataIndexes
 */
export const areAllRequiredFieldsSelected = ({
  importFields,
  headerDataIndexes,
}) => {
  return getRequiredFields({
    importFields,
    headerDataIndexes,
  }).every((requiredField) => {
    return headerDataIndexes.includes(requiredField.dataIndex);
  });
};
