// @ts-check

import { normalizeHeader } from "../normalize-header";

/**
 * @param {Object} o
 * @param {string[]} o.next
 * @param {string[]} [o.previous]
 *
 * For each next header, check if there is
 * a previous header of the same normalized value.
 *
 * If there is, then we want to know the previous
 * index, and the current.
 *
 * This can then be used to use existing config.
 *
 */
export const getPreviousHeaderIndexes = ({ next, previous }) => {
  return next.map((header) => {
    if (!previous) {
      return null;
    }

    const index = previous.findIndex((_header) => {
      return normalizeHeader(_header) === normalizeHeader(header);
    });

    return index > -1 ? index : null;
  });
};
